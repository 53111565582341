@fontFamily: PlayfairDisplay-MediumItalic;
.content {
  background: var(--foreground-rgb);
  margin-top: 50px;
  padding: 30px 24px;

  > h2 {
    margin-bottom: 50px;

    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--app-text-color);
    // font-family: @fontFamily;

    :first-child {
      color: var(--app-primary-color);
    }
  }

  .container {
    width: 100%;
    // overflow-x: auto;
    // overflow-y: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 24px;
    padding: 1rem;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      z-index: 9;
      display: inline-block;
      // min-width: 225px;
      // max-width: 282px;
      // height: 100%;
      border-radius: 28px;
      background: #e7f2e9;
      padding: 32px 24px 50px 24px;
      text-align: center;

      > .icon {
        width: 88px;
        height: 88px;
        min-width: 88px;
        max-width: 88px;
        min-height: 88px;
        max-height: 88px;
      }

      > .title {
        margin-top: 34px;
        font-weight: bold;
        font-size: 19px;
        min-height: 50px;
        color: var(--app-text-color);
      }

      > .desc {
        margin-top: 20px;
        text-align: left;
        font-weight: 400;
        text-wrap: balance;
        font-size: 16px;
        color: var(--app-text-color);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    .container {
      grid-template-columns: repeat(2, 1fr);
      // column-gap: 24px;
      // row-gap: 24px;

      > .cardContainer {
        padding: 18px;

        > .title {
          margin-top: 15px;
        }

        > .desc {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

// @media (max-width: 900px) and (min-width: 768px) {
//   .content {
//     margin-bottom: 154px;
//   }
// }

// @media (max-width: 769px) and (min-width: 576px) {
//   .content {
//     margin-bottom: 119px;
//   }
// }

// @media screen and (max-width: 577px) {
//   .content {
//     margin-bottom: 69px;
//   }
// }

@media screen and (max-width: 576px) {
  .content {
    padding: 0;
    .container {
      .cardContainer {
        .title {
          font-size: 16px;
        }

        > .desc {
          font-size: 12px;
        }
      }
    }
  }
}

